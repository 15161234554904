
import React from 'react';
// import headerFA from '../assets/pdfs/hFondoDeAhorro.png';
// import bottomFA from '../assets/pdfs/hFondoDeAhorroAbajo.png';
import html2pdf from 'html2pdf.js';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef  } from 'react';
import { nWithCommas } from '../utils';
import { processFechaNM, eliminarAcentos, convertirCamelCase, negativos } from '../utils';


function PdfFondoAhorro({ movToShow }) {
    const appData = useSelector((state) => state.appData)
    const [saldosFecha, setSaldosFecha] = useState(false);
    const pdfRef = useRef(null);


    useEffect(() => {
  

        if (appData.saldosFecha) {
          
            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === 'FONDO DE AHORRO') {

                        setSaldosFecha(saldo);
                    }
                }
            }
        }
    }, [])


    const downloadPdf = () => {
        const input = pdfRef.current;
        html2pdf(input, {
            margin: 10,
            filename: 'fondo_ahorro_report.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
       // onDownloadPdf();
    };

    return (
        <div ref={pdfRef} className="row">
            <div className='col s12'>
                {/* <img src={headerFA} alt="fondoAhorro" style={{ maxWidth: '100%' }} /> */}
            </div>
            <div className='col s6'>
                <span>{appData.user.Nombre}</span>
            </div>
            <div className='col s6 center-align'>
                <span>Reporte de Saldos y Movimientos</span>
                {

                }
                <div className='row'>
                    <div className='col s12'>
                        <span>Mi Plan</span>
                    </div>
                    <div className='col s6'>Aportaciones:</div>
                    <div className='col s6'>${nWithCommas(parseFloat(saldosFecha.aportaciones).toFixed(2))}</div>
                    <div className='col s6'>Retiros:</div>
                    <div className='col s6'>${nWithCommas(parseFloat(saldosFecha.Retiros).toFixed(2))}</div>
                    <div className='col s6'>Plusvalía/Minusvalía:</div>
                    <div className='col s6'>${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}</div>
                </div>
            </div>
            <div className='col s6 offset-s6'>
                {

                }
                <div className='row'>
                    <div className='col s12'>
                    </div>
                    <div className='col s6'>Saldo Total:</div>
                    <div className='col s6'>${nWithCommas(parseFloat(saldosFecha.TotalLiquidar).toFixed(2))}</div>
                </div>
            </div>
            <div className='col s12'>
                <span>Resumen de Mi Fondo</span>
            </div>
            <div className='row'>
                <div className='col s12'>
                </div>
                <div className='col s6'>Informacion al {saldosFecha.FechaUltActividad}</div>
            </div>

            <div className="row">
                <div className="col s4">+ Aportaciones</div>
                <div className="col s4">+ Rendimientos</div>
                <div className="col s4">- Prestamo</div>
            </div>
            <div className="row">
                <div className="col s4">${nWithCommas(parseFloat(saldosFecha.aportaciones).toFixed(2))}</div>
                <div className="col s4">${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}</div>
                <div className="col s4">${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}</div>
            </div>
            <div className="row">
                <div className="col s4 offset-s8">Total de Mi Fondo: ${nWithCommas(parseFloat(saldosFecha.TotalLiquidar).toFixed(2))}</div>
            </div>
            <div className="row">
                <div className='col s12'>
                    {/* <img src={bottomFA} alt="valmex.com" style={{ maxWidth: '100%' }} /> */}
                </div>
            </div>
            <div className="row">
                <div className='col s12'>
                    {/* <img src={headerFA} alt="fondoAhorro" style={{ maxWidth: '100%' }} /> */}
                </div>
            </div>
            <div className='col s6'>
                <span>Movimientos de Mi Fondo</span>
                <div className="row">
                    <div className="col s3">Fecha</div>
                    <div className="col s3">Cuenta</div>
                    <div className="col s3">Tipo de Movimiento</div>
                    <div className="col s3">Monto</div>
                </div>
                <div className="row">
                    <div className="col s3">$</div>
                    <div className="col s3">${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}</div>
                    <div className="col s3">${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}</div>
                    <div className="col s3">${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}</div>
                </div>
            </div>    
            <button className="waves-effect waves-light btn" onClick={downloadPdf}>
                        Descargar
                    </button>
                    {
                    movToShow && Object.keys(movToShow).length ?
                        <React.Fragment>
                            {
                                Object.keys(movToShow).reverse().map((v, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="col s12 left-align ft-10 vlmx-navy-80 txtBold mt-1">
                                                {processFechaNM(v)}
                                            </div>
                                            {
                                                movToShow[v].length ?
                                                    movToShow[v].map((d, j) => {

                                                        return (
                                                            <div className="col s12" key={j}>
                                                                <div className={`row  mb-0 
                                                        ${d.Monto < 0 || (d.Concepto && eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo') ? 'tipo_negativo' : 'tipo_normal'}
                                                        `} >
                                                                    <div className="col s6 left-align ft-12 vlmx-navy-80">
                                                                        {d.Cuenta ? convertirCamelCase(d.Cuenta) : convertirCamelCase(d.Concepto)}
                                                                    </div>
                                                                    <div className="col s6 right-align ft-14 vlmx-navy-80">
                                                                        {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    : null
                                            }

                                        </React.Fragment>
                                    )
                                })
                            }
                            <div style={{ height: '400px' }} ></div>
                        </React.Fragment>

                        : <div className="col s12 mt-1 left-align txtBold" style={{ height: '400px' }} >
                            No se encontró información
                        </div>
                }
        </div>
    )
}


export default PdfFondoAhorro;