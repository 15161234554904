import React from "react";
import { useState, useEffect } from "react";
import Logo from '../assets/logo.svg'
import M from 'materialize-css';
import {
    setUser, setToken, setIdToken, loadUser, setLegales, toogleLoad,
    setTokenSesion, setCoordenadas
} from "../redux/slices/appslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { encryptAES, getCoordenadas } from "../utils";
import { BASE_URL } from "../properties";
import Terminos from '../components/terminos';
import { getLegales, setBitacora, getDatosInactividad } from '../firestore';
import Token from "../components/token";
import { solicitaCodigoLogin, validaCodigoLogin, cerrarSesion } from '../ws';



function Login() {


    const appData = useSelector((state) => state.appData)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const locationrr = useLocation();
    const params = useParams();
    const [loginData, setLogginData] = useState({ usuario: "1237TEST9265E", password: "3str3llitA01", errorlogin: false })
    const [muestraToken, setMuestraToken] = useState(false);
    const [errorToken, setErrorToken] = useState(false);
    const [sesionActiva, setSesionActiva] = useState(false);
    const [toogleEye, setToogleEye] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeInactividad, setDataInactividad] = useState(false);

    useEffect(() => {

        dispatch(loadUser())
        legalesF();
        dataInactividad();
        initCoordenadas();
        M.updateTextFields();

        if (window.navigator.userAgent.indexOf("Edg") > -1) {
            let ojos = document.getElementsByClassName('field-icon');

            if (ojos.length) {
                for (let ojo of ojos) {
                    ojo.style.display = 'none';
                }
            }

        }

        setTimeout(() => {
            document.getElementById('lblUsr').classList.add('active')
            document.getElementById('lblPass').classList.add('active')
        }, 500)
        M.Modal.init(document.getElementById('modalSesion'), {
            dismissible: false,
        });

    }, [])

    useEffect(() => {
        M.updateTextFields();
    }, [loginData])

    const legalesF = async () => {
        let legales = await getLegales();
        M.Modal.init(document.querySelectorAll('.modal'), {
            onCloseStart: (e) => {


                if (e.id === 'modalSesion') {
                    setMuestraToken(false);
                }
            }
        });
        dispatch(setLegales(legales));
    }

    const dataInactividad = async () => {

        let dataIN = await getDatosInactividad();

        setDataInactividad(dataIN);
    }

    const passTemporal = async () => {
        setLoading(true);
        if (loginData.usuario.trim() !== "") {
            let dataToEncrypt = {
                "Usuario": loginData.usuario,
                "DeviceToken": "",
                "SistemaOperativo": "",
                "AppID": ""
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/loggin/EnvioContraseniaTemporal`, requestOptions)
                .then(response => response.json())
                .then((data) => {
                    setLoading(false);
                    if (data && data.BanderaExito) {

                        let mInstance = M.Modal.getInstance(document.getElementById('pass_temporal'));
                        mInstance.open();

                    } else {
                        if (data && data.Mensaje && data.Mensaje.trim() !== "") {
                            setLogginData({ ...loginData, errorlogin: data.Mensaje.trim() })
                        }
                    }
                }).catch((e) => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setLogginData({ ...loginData, errorlogin: 'Ingresa tu usuario' })
        }

    }

    async function initCoordenadas() {
        try {
     
            let coordenadas = await getCoordenadas();
            if (coordenadas) {
                dispatch(setCoordenadas(coordenadas))
            }
        } catch (error) {

        }
    }

    async function submitLogin(e) {

        dispatch(toogleLoad(true));
        if (e) {
            e.preventDefault()
        }

        let coordenadas = appData.coordenadas;

        try {
            if (!coordenadas) {
                coordenadas = await getCoordenadas();
                if (coordenadas) {
                    dispatch(setCoordenadas(coordenadas))
                }
            }

        } catch (error) {

        }

        if (coordenadas) {

            let dataToEncrypt = {
                "Usuario": loginData.usuario,
                "Contrasenia": loginData.password,
                "DeviceToken": "",
                "SistemaOperativo": "",
                "AppID": ""
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };
      
            fetch(`${BASE_URL}/loggin/ValidaUsuario`, requestOptions)
                .then(response => response.json())
                .then((data) => {
                   
                    if (data.Datos && data.JWT && data.firebase && data.firebase.idToken) {



                        let usuario = JSON.parse(JSON.stringify(data.Datos));

                        usuario.usuarioLogin = loginData.usuario;
                        usuario.timeInactividad = timeInactividad;

                        dispatch(setUser(usuario))
                        dispatch(setToken(data.JWT))
                        dispatch(setIdToken(data.firebase.idToken))
                        dispatch(setTokenSesion(data.JWT.access_token))

                        setBitacora({
                            data: {
                                fecha: Date.now(),
                                ip: appData.ip,
                                latitude: appData.coordenadas.latitude,
                                longitud: appData.coordenadas.longitude,
                                lugar: appData.coordenadas.direccion,
                                navegador: appData.coordenadas.navegador
                            },
                            idUsuario: data.Datos.IdUsuario
                        })

                        dispatch(toogleLoad(false));

                        navigate('/')
                    } else {
                        dispatch(toogleLoad(false));

                        if (data.IdError && data.IdError === 76) {

                            setSesionActiva({
                                idUsuario: data.Mensaje.idUsuario,
                                sesion: data.Mensaje.sesion,
                                token: data.JWT.access_token,
                                refreshToken: data.JWT.refresh_token,
                                idToken: data.firebase.idToken,
                                JWT: data.JWT
                            });

                            let instance = M.Modal.getInstance(document.getElementById('modalSesion'));
                            if (instance) {
                                instance.open();
                            }
                        } else if (data.IdError && (data.IdError === 74 || data.IdError === 16)) {
                            setLogginData({ ...loginData, errorlogin: "No fue posible iniciar sesión, por seguridad es necesario comunicarse a Valmex." })
                        } else {
                            if (data.Mensaje) {
                                if (data.Mensaje && data.Mensaje.mensaje) {
                                    setLogginData({ ...loginData, errorlogin: data.Mensaje.mensaje })
                                } else if (data.Mensaje && typeof data.Mensaje == 'string') {
                                    setLogginData({ ...loginData, errorlogin: data.Mensaje })
                                }

                            } else {
                                setLogginData({ ...loginData, errorlogin: "No logró iniciar sesión intente nuevamente." })
                            }
                        }


                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        } else {
            dispatch(toogleLoad(false));
        }
    }

    async function solCodigoLogin() {
        try {
            dispatch(toogleLoad(true));
            let result = await solicitaCodigoLogin(sesionActiva);
            if (result && result.BanderaExito) {
                setMuestraToken(true);
            }
            dispatch(toogleLoad(false));
        } catch (error) {
            dispatch(toogleLoad(false));
        }
    }

    async function validaCodigoL(token) {
        try {
            dispatch(toogleLoad(true));
            let dataSend = JSON.parse(JSON.stringify(sesionActiva));
            dataSend.CodigoSeg = token;
            let result = await validaCodigoLogin(dataSend);
            if (result && result.BanderaExito) {

                let resultCierra = await cerrarSesion({
                    user: {
                        usuarioLogin: loginData.usuario,
                        IdUsuario: sesionActiva.idUsuario,
                        Sesion: sesionActiva.sesion,

                    },
                    tokenSesion: result.JWT.current_token, //sesionActiva.idToken,
                    token: {
                        refresh_token: sesionActiva.refreshToken
                    },
                    authorizacion: sesionActiva.idToken,
                    codigoSeg: dataSend.CodigoSeg,
                    password: loginData.password

                });
                if (resultCierra && resultCierra.BanderaExito) {
                    dispatch(toogleLoad(false));
                    M.Modal.getInstance(document.getElementById('modalSesion')).close();
                    await submitLogin();
                }
            } else {
                setErrorToken('Token Incorrecto')
            }
            dispatch(toogleLoad(false));
        } catch (error) {
            dispatch(toogleLoad(false));
        }
    }

    function fToogleEye() {
        setToogleEye(!toogleEye)
    }

    return (
        <React.Fragment>
            <div className="loginModule" style={{ height: "100vh" }}>
                <div className="container containerLogin">
                    <div className="row">
                        <div className="col s12 loginForm">
                            <div className="row">
                                <div className="col s12 m12 l6 xl6 white wrapperLogin">
                                    <div className="valign-wrapper center-align">
                                        <form className="row w-100" onSubmit={async (e) => {
                                            await submitLogin(e);
                                        }}>
                                            <div className="col s12">
                                                <img src={Logo} alt="logo" />
                                            </div>
                                            <div className="col s12 mt-2">
                                                <span className="ft-20">¡Bienvenido!</span>
                                            </div>

                                            <div className="input-field col s12">
                                                <input id="user" type="text"
                                                    value={loginData.usuario}
                                                    onChange={(e) => {
                                                        setLogginData({ ...loginData, usuario: e.target.value, errorlogin: false })
                                                    }}
                                                    className={loginData.errorlogin ? 'inputErr' : ''}
                                                />
                                                <label htmlFor="user" id="lblUsr" className={loginData.errorlogin ? 'lblErr' : ''}>Usuario</label>
                                                <span className="field-icon">

                                                    {
                                                        loginData.errorlogin ?
                                                            <span className="material-icons red-text">
                                                                error
                                                            </span> : null
                                                    }
                                                </span>
                                                <span className="helper-text red-text left" >{loginData.errorlogin}</span>
                                            </div>
                                            <div className="input-field col s12">
                                                <input type={toogleEye ? 'text' : 'password'} name='password' id='password'
                                                    value={loginData.password}
                                                    className={loginData.errorlogin ? 'inputErr' : ''}
                                                    onChange={(e) => {
                                                        setLogginData({ ...loginData, password: e.target.value, errorlogin: false })
                                                    }}
                                                />
                                                <label htmlFor='password' id="lblPass" className={loginData.errorlogin ? 'lblErr' : ''}>Contraseña</label>
                                                <span onClick={fToogleEye} className="field-icon toggle-password">
                                                    <span className="material-icons">
                                                        {
                                                            toogleEye ?
                                                                'visibility_off' : 'visibility'
                                                        }
                                                    </span>
                                                </span>
                                            </div>

                                            <div className="col s12">
                                                <span className="linksC ft-14"><span className="cursorPointer"
                                                    onClick={async () => {
                                                        await passTemporal();
                                                    }}
                                                >Olvidé mi contraseña</span></span>
                                            </div>

                                            <div className="col s12 mt-1">
                                                <button
                                                    id="btnLogin"
                                                    disabled={loginData.usuario.trim().length === 0 || loginData.password.trim().length === 0
                                                        || loading
                                                    }
                                                    type="submit" className="btn btn-webSi-primary waves-effect light">Iniciar Sesión</button>
                                            </div>
                                            <div className="col s12 mt-1">
                                                <span className="linksC modal-trigger cursorPointer" href="#aviso_privacidad">Ver términos y condiciones</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col s12 m12 l6 xl6 ">
                                    <div className="row pl-54_externo">

                                        <div className="col s12 mt-1">
                                            <button className="btn waves-effect light btnAct white mw-200" onClick={() => {
                                                navigate('/faqs')
                                            }}>
                                                Preguntas Frecuentes
                                            </button>
                                        </div>
                                        <div className="col s12 mt-1">
                                            <button className="btn waves-effect light btnAct white mw-200" onClick={() => {
                                                navigate('/conocenos')
                                            }}>
                                                Conócenos
                                            </button>
                                        </div>

                                        <div className="col s12 mt-1">
                                            <button className="btn waves-effect light btnAct white mw-200" onClick={() => {
                                                navigate('/calcular_retiro')
                                            }}>
                                                Calcula tu Pensión
                                            </button>
                                        </div>


                                        <div className="col s12 mt-1">
                                            <button className="btn waves-effect light btnAct white mw-200" onClick={() => {
                                                navigate('/educacion_financiera')
                                            }}>
                                                Educación Financiera
                                            </button>
                                        </div>



                                    </div>
                                    <div className="col s12 boldText mt-4_externo">
                                        <div className="row pl-54_externo">
                                            <div className="col s12 left-align ft-18 boldText">
                                                Contacto
                                            </div>
                                            <div className="col s12 ft-16">
                                                <p>Los horarios de atención son de Lunes a Jueves de 9:00 a 18:00 hrs y Viernes de 9:00 a 15:00 hrs.</p>
                                                <p>Puedes contactarnos:</p>
                                            </div>
                                            <div className="col s12">
                                                <div className="card white br-8">
                                                    <div className="card-content ft-16">
                                                        <p className="boldText ">Valmex</p>
                                                        <p > <a href="tel:+525552791433" className="no_txt_deco cursorPointer labelNavy">(+52) 55 5279 1433</a></p>
                                                        <p><a href="mailto:solucionesempleados@valmex.mx" className="no_txt_deco cursorPointer labelNavy">solucionesempleados@valmex.mx</a></p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="aviso_privacidad" className="modal">
                    <div className="modal-content">
                        <Terminos centerTitle={true} />
                    </div>
                    <div className="modal-footer">
                        <a href="#!" className="modal-close waves-effect waves-green btn-flat vlmx-navy-80 No_txtDeco">Aceptar</a>
                    </div>
                </div>

                <div id="pass_temporal" className="modal">
                    <div className="modal-content">
                        <div className="row">
                            <div className="col s12 mt-2 center-align">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="col s12 mt-2 center-align">
                                <span className="ft-24 boldText">¡Te enviaremos tu contraseña temporal!</span>
                            </div>
                            <div className="col s12 mt-2 center-align">
                                <span className="ft-16 fw-300"> Se enviará una contraseña a tu correo electrónico o celular registrado</span>
                            </div>
                            <div className="col s12 mt-2 center-align">
                                <button type="button" className="btn btn-webSi-primary waves-effect light modal-close"
                                    style={{ width: '328px !important' }}
                                >Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modalSesion" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 right-align">
                            <i className="material-icons cursorPointer modal-close" >close</i>
                        </div>
                    </div>
                    {
                        muestraToken ?
                            <div className="row">
                                <div className="col s12 center-align mt-1">

                                    <img src={Logo} alt="logo" />

                                </div>
                                <div className="col s12 center-align txtBold mt-2">

                                    Por último
                                </div>

                                <div className="col s12 center-align mt-1">
                                    Ingresa el código que se te envió a tu correo electrónico
                                </div>
                                <div className="col s12 left-align mt-1 red-text">
                                    {errorToken ? errorToken : null}
                                </div>
                                <div className="col s12 center-align mt-1">
                                    <Token callBack={validaCodigoL} />
                                </div>
                                <div className="col s12 center-align mt-1">
                                    <span className="ft-12 cursorPointer" onClick={() => {
                                        solCodigoLogin()
                                    }
                                    }>Solicitar otro código</span>
                                </div>


                            </div>

                            :

                            <div className="row">
                                <div className="col s12 mt-1 left-align txtBold ft-16">
                                    Error de sesión
                                </div>
                                <div className="col s12 mt-1 ft-14 txtBold">
                                    Tu usuario cuenta con una sesión activa,
                                    ¿Deseas cerrar la sesión activa?
                                </div>
                                <div className="col s12 mt-1 right-align">
                                    <button className="btn-flat"
                                        onClick={async () => {
                                            solCodigoLogin();
                                        }}
                                    ><span className="primaryText txtBold">Sí</span></button> <button className="modal-close btn-flat"><span className="primaryText txtBold">No</span></button>
                                </div>
                            </div>
                    }

                </div>

            </div>

            {
                //temporal
            }



        </React.Fragment>
    )
}

export default Login;