import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router"
import { ICONS } from "../assets/icons";
import paso1Rebalanceo from '../assets/paso1Rebalanceo.svg';
import paso2Rebalanceo from '../assets/paso2Rebalanceo.svg';
import paso3Rebalanceo from '../assets/paso3Rebalanceo.svg';
import { useDispatch, useSelector } from "react-redux";
import { obtenerFechaActualString, encryptAES, nWithCommas } from '../utils';
import M from 'materialize-css';
import { BASE_URL } from "../properties";
import { toogleLoad, setTokenSesion, } from "../redux/slices/appslice";




function SolicitudRebalanceo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const appData = useSelector((state) => state.appData)

    const [paso, setPasos] = useState(1);
    const [saldosPorCuenta, setSaldosPorCuenta] = useState(false);
    const [dataSolicitud, setDataSolicitud] = useState({});
    const [valoresRebalanceo, setValoresRebalanceo] = useState({});
    const [rebalanceoValido, setRebalanceoValido] = useState(false);

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '',
        producto: ''
    });


    useEffect(() => {
        if (params.tipo && params.tipo === 'plandepensiones') {

            setDataPage({ titulo: 'Fondo de Pensiones', to_return: '/plandepensiones',  });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            setDataPage({ titulo: 'Caja de Ahorro', to_return: '/cajadeahorro' });

        } else {
            navigate('/')
        }

    }, [params])


    useEffect(() => {
        rebalanceoValidoF();
    }, [valoresRebalanceo]);

    useEffect(() => {

        if(dataPage.titulo !== ""){
            getSaldosPorCuenta();
        }
        
    }, [dataPage]);



    const buscarProducto = (producto, productos) => {
        console.log(productos);
        const existe = productos.filter(p => p.TipoCliente === producto);

        return existe;
    }



    const getSaldosPorCuenta = async () => {
        dispatch(toogleLoad(true));
        
        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)

        if (dataUserProducto.length && appData.token && appData.tokenSesion) {

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20240801",
                "FechaFin": obtenerFechaActualString(),
                "TodaLaHistoria": "True",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token,
                "DeviceToken": "dv1"
            }

            console.log(JSON.stringify(dataToEncrypt));

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/saldosPorCuenta`, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    if (data.Datos && data.JWT && data.Datos.length) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));

                        setSaldosPorCuenta(data.Datos);

                    } else {
                        dispatch(toogleLoad(false));
                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        }


    }


    const rebalanceoValidoF = async () => {
        //obtiene el tamaño de sados por cuenta.
        let totalValores = saldosPorCuenta.length;

        let sumaValores = 0;
        //obtiene la suma total de los valores para rebalanceo
        for (let key of Object.keys(valoresRebalanceo)) {
            sumaValores += parseInt(valoresRebalanceo[key]);
        }

        console.log(sumaValores);

        if (sumaValores === 100) {
            setRebalanceoValido(true);
        } else {
            setRebalanceoValido(false);
        }
    }


    const generaSolicitud = async () => {
         dispatch(toogleLoad(true));

        const dataUserProducto = buscarProducto(dataPage.titulo, appData.user.Productos)
        if(Object.keys(valoresRebalanceo).length && rebalanceoValido && dataUserProducto.length){

            let listaDistribucion = [];

            for(let key of Object.keys(valoresRebalanceo)){

                const _v = key.split('_');


                listaDistribucion.push({
                    IdCuenta: parseInt(_v[0]),
                    IdPlan: parseInt(_v[1]),
                    Porcentaje: parseInt(valoresRebalanceo[key])
                })
            }   

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni":"20100101",
                "FechaFin":obtenerFechaActualString(),
                "ListaDistribucion":listaDistribucion,
                "DeviceToken":"dv1",
                "SistemaOperativo":"web",
                "AppID":"SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/rebalanceos/solicitudRebalanceo`, requestOptions)
                .then(response => response.json())
                .then((data) => {
                 
                    if (data.Datos && data.JWT && data.BanderaExito) {

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));

                        setPasos(2);

                    } else if(data.BanderaExito === 0 && data.Mensaje.trim() !== "") {
                        dispatch(toogleLoad(false));
                        M.toast({classes:'red accent-4 white-text txtBold', html: data.Mensaje});
                    }
                }).catch((e) => {
                    M.toast({classes:'red accent-4 white-text txtBold', html: "Ocurrio un error inesperado intente nuevamente."});
                    dispatch(toogleLoad(false));

                });
        }
    }


    function getTextPaso() {


        if (paso === 1) {
            return "1. Configura el porcentaje de inversión "
        }

        if (paso === 2) {
            return "2. Confirmación de solicitud"
        }

        return "";
    }

    return (
        <div className="container mt-2" style={{ minHeight: '90vh' }}>
            <div className="row">

                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> <span className="ft-16 txtBold">{dataPage.titulo}</span></p>
                </div>
                <div className="col s4 left-align">
                    <span className="ft-24 boldText">Rebalancear Inversión</span>
                </div>
                <div className="col s4 center-align mt-">
                    <span className="ft-14 ">{getTextPaso()}</span>
                </div>
                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    {
                        paso == 1 ?
                            <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                                onClick={() => {
                                    navigate(dataPage.to_return)
                                }}
                            ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cancelar</a>
                            : null

                    }

                </div>
                <div className="col s12">
                    <div className="row">

                        <div className="col s4"></div>
                        <div className="col s4 center-align">

                            {
                                paso === 1 ?
                                    <img src={paso2Rebalanceo} alt="paso" />
                                    : null
                            }
                            {
                                paso === 2 ?
                                    <img src={paso3Rebalanceo} alt="paso" />
                                    : null
                            }

                        </div>

                        <div className="col s4"></div>
                    </div>
                </div>
            </div>

            <div className="row" hidden={paso === 2}>
                <div className="col s12">
                    <p className="mb-0">Selecciona un fondo y ajusta el porcentaje de Inversión, <span className="colorDarkBlue boldText">tomando en cuenta que la suma sea 100% de tus recursos.</span></p>
                    <p className="mt-0">Los cambios se reflejarán una vez sean aprobados por el administrador.</p>
                </div>

                <div className="col s12">
                    <table>
                        <thead>
                            <tr className="borderCyan">
                                <th>Fondo</th>
                                <th className="right-align">Saldo Actual</th>
                                <th className="right-align">Porcentaje Actual</th>
                                <th className="right-align">Porcentaje Deseado</th>
                            </tr>
                        </thead>
            
                    <tbody>

                        {
                            saldosPorCuenta.length && saldosPorCuenta.map((f) => {
                                return (
                                    <tr className="borderCyan">
                                        <td>{f.Cuenta}</td>
                                        <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`$${nWithCommas(parseFloat(f.SaldoIni).toFixed(2))}`} disabled /> </td>
                                        <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" value={`${!f.porcentaje ? '100%' : '0%'}`} disabled /></td>
                                        <td className="container_inputRebalanceo"><input type="text" className="txt_rebalanceo" id={f.IdCuenta + "_" + f.IdPlan}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    let copyValores = { ...valoresRebalanceo };

                                                    copyValores[f.IdCuenta + "_" + f.IdPlan] = e.target.value;

                                                    setValoresRebalanceo(copyValores)
                                                }

                                            }}
                                            value={valoresRebalanceo[f.IdCuenta + "_" + f.IdPlan]} /></td>
                                    </tr>
                                )
                            })


                        }

                        <tr>
                            <td colSpan={2}></td>
                            <td><button className="waves-effect waves-teal btn-flat"
                            onClick={()=>{
                                let copyValores = { ...valoresRebalanceo };

                                for(let key of Object.keys(copyValores)){
                                    copyValores[key] = "";
                                }

                                setValoresRebalanceo(copyValores);
                            }}
                            > <i className="material-icons left">clear</i>Descartar Cambios</button></td>
                            <td className="right-align"><button className="btn btnContinueF5" disabled={!rebalanceoValido}
                                onClick={() => {
                                    generaSolicitud();
                                }}
                            >Guardar Cambios</button></td>
                        </tr>

                    </tbody>
                    </table>
                </div>
            </div>

            <div className="row" hidden={paso === 1}>
                <div className="col s12">
                    <div className="row center-align">
                        <div className="col s12 mt-4">
                            <img src={ICONS.solicitudAprobada} alt="solicitud aprobada" />
                        </div>
                        <div className="col s12 ft-24 mt-2 colorDarkBlue">
                            Se ha enviado la solicitud para hacer los cambios

                        </div>
                        <div className="col s12 ft-16 ">

                            <p className="mb-0">Los cambios se verán reflejados en máximo 24 hrs.</p>

                        </div>
                        <div className="col s12 mt-1">
                            <button className="btn btnContinueF5"  onClick={() => {
                                navigate(dataPage.to_return)
                            }}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SolicitudRebalanceo;