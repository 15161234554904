
import React, { useEffect } from "react";
import { createRoutesFromElements, createBrowserRouter, Route, Outlet } from "react-router-dom";
import Login from '../pages/login';
import OlvidePass from "../pages/olvidePass";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { PublicRoute } from "../components/PublicRoute";
import { PublicAllRoute } from "../components/AllRoute";
import { loadUser, setPreguntas, setIp, setLegales, setProductosInfo, setEduFinanciera } from '../redux/slices/appslice'
import { useDispatch } from "react-redux";
import { MainLayout } from "../components/mainLayout";
import MisBalances from '../pages/misBalances'
import CajaDeAhorro from "../pages/cajaDeAhorro";
import FondosDeAhorro from "../pages/fondosDeAhorro";
import PlanDePensiones from "../pages/planDePensiones";
import Perfil from "../pages/perfil";
import AcercaDe from "../pages/acercade";
import ProfileData from "../components/profileData";
import Accounts from "../components/accounts";
import Security from "../components/security";
import CambioPass from "../pages/cambioPass";
import Contacto from "../components/contact";
import AvisoDePrivacidad from "../components/aviso";
import Terminos from "../components/terminos";
import { getPreguntas, getLegales, getProductosInfo, getEducacionFinanciera } from "../firestore";
import Conocenos from "../pages/conocenos";
import Faqs from "../pages/faqs";
import BitacoraAcceso from "../pages/bitacora";
import { getIp } from "../utils";
import { NavNoSession } from "../components/navNoSession";
import Prestamos from "../pages/prestamos";
import SaldosMovimientos from "../pages/saldosMovimientos";
import EducacionFinanciera from "../pages/educacionFinanciera";
import CalculadoraRetiro from "../pages/calculoRetiro";
import Products from "../components/products";
import PortafolioInversion from "../pages/portafolioInversion";
import InfoFondos from "../components/infoFondos";
import SolicitudPrestamo from "../pages/solicitudPrestamo";
import SolicitudRetiro from "../pages/solicituRetiro";
import SolicitudRebalanceo from "../pages/solicitudRebalanceo";
// import HandleJWTDEMOSSO from "../components/pageDemoSSO";

const SessionProvider = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUser());
    preguntasF();
    
    getIPUser();
    legalesF();
    getProductos();
    getEduFinanciera();
  }, []);

  const getEduFinanciera = async () =>{
    let eduFinan = await getEducacionFinanciera();
    dispatch(setEduFinanciera(eduFinan));
  }

  const legalesF = async () => {
    let legales = await getLegales();
    dispatch(setLegales(legales))
}

  const preguntasF = async () => {
    let preguntas = await getPreguntas();
    dispatch(setPreguntas(preguntas));
  }

  const getProductos = async () =>{
    let productos = await getProductosInfo();
    dispatch(setProductosInfo(productos))
  }

  const getIPUser = async () => {
    let ip = await getIp();
    if (ip && ip.ip) {
      dispatch(setIp(ip.ip))
    }
  }


  return <Outlet />;
};

export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route element={<SessionProvider />} >
      <Route element={<MainLayout />}>
        <Route path="/" element={<ProtectedRoute element={<MisBalances />} />} />
        <Route path="/fondodeahorro" element={<ProtectedRoute element={<FondosDeAhorro />} />} />
        <Route path="/cajadeahorro" element={<ProtectedRoute element={<CajaDeAhorro />} />} />
        <Route path="/plandepensiones" element={<ProtectedRoute element={<PlanDePensiones />} />} />
        <Route path="/prestamos/:tipo" element={<ProtectedRoute element={<Prestamos />} />} />
        <Route path="/solicitud/prestamo/:tipo" element={<ProtectedRoute element={<SolicitudPrestamo />} />} />
        <Route path="/solicitud/retiro/:tipo" element={<ProtectedRoute element={<SolicitudRetiro />} />} />
        <Route path="/movimientos/:tipo" element={<ProtectedRoute element={<SaldosMovimientos />} />} />
        <Route path="/portafolioDeInversion/:tipo" element={<ProtectedRoute element={<PortafolioInversion />} />} />
        <Route path="/rebalanceo/:tipo" element={<ProtectedRoute element={<SolicitudRebalanceo />} />} />
        <Route path="/calcular_retiro_s" element={<ProtectedRoute element={<CalculadoraRetiro fromLogin={true}/>} />} />
        <Route path="/educacion_financiera_s" element={<ProtectedRoute element={<EducacionFinanciera fromLogin={true} />} />} />
        <Route path="/perfil" element={<ProtectedRoute element={<Perfil />} />}>
          <Route path="" element={<ProfileData />} />
          <Route path="personales" element={<ProfileData />} />
          <Route path="cuentas" element={<Accounts />} />
          <Route path="seguridad" element={<Security />} />
          <Route path="contrasenia" element={<CambioPass />} />
          <Route path="bitacora" element={<BitacoraAcceso />} />
        </Route>
        <Route path="/acercade" element={<ProtectedRoute element={<AcercaDe />} />} >
          <Route path="contacto" element={<Contacto />} />
          <Route path="terminosycondicions" element={<Terminos />} />
          <Route path="avisodeprivacidad" element={<AvisoDePrivacidad />} />
          <Route path="productos" element={<Products />} />
          <Route path="infoFondos" element={<InfoFondos />} />
        </Route>
        <Route element={<NavNoSession/>}>
          <Route path="/conocenos" element={<PublicAllRoute element={<Conocenos />} />} />
          <Route path="/faqs" element={<PublicAllRoute element={<Faqs />} />} />
          <Route path="/educacion_financiera" element={<PublicAllRoute element={<EducacionFinanciera />} />} />
          <Route path="/calcular_retiro" element={<PublicAllRoute element={<CalculadoraRetiro />} />} />
        </Route>
        <Route path="/login" element={<PublicRoute element={<Login />} />} />
        {/* <Route path="/sso" element={<PublicRoute element={<HandleJWTDEMOSSO />} />} /> */}
        <Route path="/olvidecontrasenia" element={<PublicRoute element={<OlvidePass />} />} />


      </Route>
    </Route>
  ]))


