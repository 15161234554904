
import { encryptAES, obtenerFechaActualString, ordenaInfo } from "../utils";
import { BASE_URL } from "../properties";
import M from 'materialize-css';

export async function getSaldosFecha(appData, callback) {
    try {
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };


        const data = await fetch(`${BASE_URL}/saldos/SaldosFecha`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {

            return data;
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            }
        }

        return false;
        // });
    } catch (error) {
        M.toast({ html: "Error en servicio SF", classes: 'red accent-4 white-text txtBold' })
        return false;
    }
}

export async function getSaldosTrimestrales(appData, callback) {

    try {
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token,
            "FechaIni": "19000101",
            "FechaFin": obtenerFechaActualString(),

        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };


        const data = await fetch(`${BASE_URL}/saldos/SaldosTrimestrales`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito && data.Datos) {

            return data;
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            }
            return false;
        }

    } catch (error) {
        M.toast({ html: "Error en servicio ST", classes: 'red accent-4 white-text txtBold' });
        return false;
    }
}

export async function getMovimientos(appData, callback) {
    try {
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

  
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/saldos/movimientos`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            if (data.Datos && data.Datos.length) {

                return data;
            } else {
                return false;
            }
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            }
            return false;
        }

    } catch (error) {
        return false;
    }
}


export async function getInfoFondosWS(appData, key) {

    try {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({
                "UsuarioServicio": key
            })
        };


        const resultInfoFondos = await fetch(`${BASE_URL}/opera/infoFondos`, requestOptions)
            .then(response => response.json())
     
        if (resultInfoFondos && resultInfoFondos.response) {

            if (resultInfoFondos.response.Exito === 200) {

                return ordenaInfo(resultInfoFondos.response);
            }
        }

    } catch (error) {
        //M.toast({ html: "Error en servicio IF" });
        return false;
    }
}


//Prestamos

export async function getConfigPrestamos(appData, producto, callback) {
    try {

        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "IdConexion": producto.IdConexion,
            "IdEmpleado": producto.IdEmpleado,
            "IdCliente": producto.IdCliente,
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }
     

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/solicitudPrestamo/configuracion`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {

            if (data.Datos) {
                return data;
            } else {
                return false;
            }
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            }
            return false;
        }

    } catch (error) {
        return false;
    }
}


export async function getConfigRetiro(appData, producto, callback) {
    try {

        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "IdConexion": producto.IdConexion,
            "IdEmpleado": producto.IdEmpleado,
            "IdCliente": producto.IdCliente,
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/solicitudRetiro/configuracion`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            if (data.Datos) {
                return data;
            } else {

                return false;
            }
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            }
            return false;
        }

    } catch (error) {
        return false;
    }
}


export async function calcularPrestamo(appData, dataPrestamos, callback) {
    try {
      
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": null,
            "SistemaOperativo": "android",
            "AppID": "",
            "IdCliente": appData.configPrestamo.productoConfig.IdCliente,
            "IdEmpleado": appData.configPrestamo.productoConfig.IdEmpleado,
            "IdConexion": appData.configPrestamo.productoConfig.IdConexion,
            "IdTipoPrestamo": parseInt(dataPrestamos.tipoPrestamo),
            "IdTipoPago": dataPrestamos.tipoPago,
            "Monto": parseFloat(dataPrestamos.monto),
            "Plazos": 12,
            "SesionP": appData.configPrestamo.SesionP,
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token

        }


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/solicitudPrestamo/calculo`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {

            if (data.Datos) {

                return data;
            } else {
                return false;
            }
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            } else if (data.IdError === 9) {
                M.toast({ html: "<i class='material-icons left white-text'>do_not_disturb</i> Excediste el número de préstamos disponibles.", classes: 'red accent-4 white-text txtBold' });
                return false;
            }
            else if (data.Mensaje && data.Mensaje.toUpperCase().includes('RESTRICCIÓN PRIMARY KEY')) {
                M.toast({ html: "<i class='material-icons left white-text'>do_not_disturb</i> Excediste el número de préstamos disponibles.", classes: 'red accent-4 white-text txtBold' });
                return false;
            } else {
                M.toast({ html: `<i class='material-icons left white-text'>do_not_disturb</i> ${data.Mensaje}`, classes: 'red accent-4 white-text txtBold' });
                return false;
            }
        }



    } catch (error) {
        return false;
    }
}


export async function solicitarPrestamo(appData, dataPrestamos, callback) {
    try {
       
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": null,
            "SistemaOperativo": "android",
            "AppID": "",
            "IdCliente": appData.configPrestamo.productoConfig.IdCliente,
            "IdEmpleado": appData.configPrestamo.productoConfig.IdEmpleado,
            "IdConexion": appData.configPrestamo.productoConfig.IdConexion,
            "IdTipoPrestamo": parseInt(dataPrestamos.tipoPrestamo),
            "IdTipoPago": dataPrestamos.tipoPago,
            "Monto": parseFloat(dataPrestamos.monto),
            "Plazos": 12,
            "SesionP": appData.configPrestamo.SesionP,
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token

        }

    

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/solicitudPrestamo/solicitar`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {

            if (data.Datos) {
               
                return data;
            } else {
                return false;
            }
        } else {
            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            } else if (data.Mensaje && data.Mensaje.toUpperCase().includes('RESTRICCIÓN PRIMARY KEY')) {
                M.toast({ html: "<i class='material-icons left white-text'>do_not_disturb</i> Excediste el número de préstamos disponibles.", classes: 'red accent-4 white-text txtBold' });
                return false;
            } else {
                M.toast({ html: `<i class='material-icons left white-text'>do_not_disturb</i>. ${data.Mensaje}`, classes: 'red accent-4 white-text txtBold' });
                return false;
            }
        }



    } catch (error) {
        return false;
    }
}

export async function solicitarRetiro(appData, dataPrestamos, callback) {
    try {

        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "dv1",
            "IdCliente": appData.configRetiro.productoConfig.IdCliente,
            "IdEmpleado": appData.configRetiro.productoConfig.IdEmpleado,
            "IdConexion": appData.configRetiro.productoConfig.IdConexion,
            "IdTipoRetiro": dataPrestamos.tipoRetiro,
            "Monto": dataPrestamos.monto,
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token

        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/solicitudRetiro/solicitar`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {

            if (data.Datos) {
               
                return data;
            } else {
                return false;
            }
        } else {

            if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                if (callback) {
                    callback();
                }
            } else if (data.IdError === 10) {
                M.toast({ html: "<i class='material-icons left white-text'>do_not_disturb</i> Excediste el número de retiros disponibles.", classes: 'red accent-4 white-text txtBold' });
                return false;
            } else if (data.Mensaje && data.Mensaje.toUpperCase().includes('RESTRICCIÓN PRIMARY KEY')) {
                M.toast({ html: "<i class='material-icons left white-text'>do_not_disturb</i> Excediste el número de retiros disponibles.", classes: 'red accent-4 white-text txtBold' });
                return false;
            } else {
                M.toast({ html: `<i class='material-icons left white-text'>do_not_disturb</i>. ${data.Mensaje}`, classes: 'red accent-4 white-text txtBold' });
                return false;
            }
        }



    } catch (error) {
        return false;
    }
}

export async function updateSesion(appData) {
    try {
        let dataToEncrypt = {
            "Usuario": "ServiciosDemo",
            "ContraseniaServicio": "W3bS3rv1c3s_#d3mo",
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP"

        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/loggin/ActualizaSesion`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            if (data.Datos && data.Datos.length) {

                return data;
            } else {
                return false;
            }
        } else {

            return false;
        }

    } catch (e) {
        return false;
    }
}


export async function solicitaCodigoLogin(appData) {
    try {

        let dataToEncrypt = {
            "Usuario": "ServiciosDemo",
            "ContraseniaServicio": "W3bS3rv1c3s_#d3mo",
            "IdUsuario": `${appData.idUsuario}`,
            "Sesion": appData.sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            'token': appData.token,
            "refresh_token": appData.refreshToken

        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/loggin/codigoSeguridad`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            return data;
        } else {

            return false;
        }

    } catch (e) {
        return false;
    }
}

export async function aceptaTerminosYCondiciones(appData) {
    try {

        let dataToEncrypt = {
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Usuario": 'ServiciosDemo',
            "Sesion": appData.user.Sesion,
            "AceptacionAviso": 1,
            "TerminosYCondiciones": 1,
            "DeviceToken": '0',
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/loggin/aceptacionAvisoPrivacidad`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            return data;
        } else {

            return false;
        }
    } catch (error) {
        return false;

    }
}

export async function validaCodigoLogin(appData) {
    try {

        let dataToEncrypt = {
            "Usuario": "ServiciosDemo",
            "ContraseniaServicio": "W3bS3rv1c3s_#d3mo",
            "IdUsuario": `${appData.idUsuario}`,
            "Sesion": appData.sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.token,
            "CodigoSeg": appData.CodigoSeg,
            "JWT": appData.JWT,
            "refresh_token": appData.refreshToken

        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };

        let data = await fetch(`${BASE_URL}/loggin/validaCodigoSeguridad`, requestOptions)
            .then(response => response.json())

        if (data.BanderaExito) {
            return data;
        } else {

            return false;
        }

    } catch (e) {
        return false;
    }
}

export async function cerrarSesion(appData) {


    let dataToEncrypt = {
        "Usuario": appData.user.usuarioLogin,
        "IdUsuario": `${appData.user.IdUsuario}`,
        "Sesion": appData.user.Sesion,
        "DeviceToken": "",
        "SistemaOperativo": "web",
        "AppID": "SAF_APP",
        "token": appData.tokenSesion,
        "refresh_token": appData.token.refresh_token,
    }

    try {


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.authorizacion || appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };
  
        let data = await fetch(`${BASE_URL}/loggin/FinSesion`, requestOptions)
            .then(response => response.json())

        if(data.BanderaExito){
            return data;
        }else{
            return data.Mensaje;
        }
    } catch (e) {
        return false;
    }

}